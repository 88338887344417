var MARKER_COLOR_ARRAY =
  localStorage.getItem('iconList') !== null
    ? JSON.parse(localStorage.getItem('iconList'))
    : [];

for (const key in MARKER_COLOR_ARRAY) {
  if (MARKER_COLOR_ARRAY.hasOwnProperty(key)) {
    MARKER_COLOR_ARRAY[key] = JSON.parse(MARKER_COLOR_ARRAY[key]);
  }
}

export default {
  // dev details

  // ADMIN_API_URL: 'https://dev.hpindicators.com/api_dev/api/admin/',
  // CLIENT_API_URL: 'https://dev.hpindicators.com/api_dev/api/client/',
  // DOWNLOAD_API_URL: 'https://dev.hpindicators.com/api_dev',
  // // CLIENT_API_URL: 'https://hpindicators.com/api/api/client/',
  // // CLIENT_API_URL: 'https://192.168.1.252:99/hpibackend/index.php/api/client/',
  // DEFAULT_API_URL: 'https://dev.hpindicators.com/api_dev/api/',
  // DEFAULT_ERROR_MSG: 'Internal server process error. Try again later.',
  // CONNECTION_ERROR_1: 'Oops! Something went wrong!',
  // CONNECTION_ERROR_2:
  //   'Problem with internet connection. Please make sure that your device is not switched to airplane mode.',
  // SITE_NAME: 'High Performance Indicators',
  // SITE_URL: 'https://dev.hpindicators.com/',
  // PROJECT_FOLDER_RELATIVE_PATH: '',
  // REC_PER_PAGE: 10,
  // USER_PROFILE_IMAGE_PATH: 'https://dev.hpindicators.com/api_dev/files/profile/',
  // USER_PROFILE_THUMB_IMAGE_PATH:
  //   'https://dev.hpindicators.com/api_dev/files/profile/thumb/',
  // MAX_DATALIST_SELECTION: 5,

  //live server details

  ADMIN_API_URL: 'https://www.hpindicators.com/api/api/admin/',
  CLIENT_API_URL: 'https://www.hpindicators.com/api/api/client/',
  DOWNLOAD_API_URL: 'https://www.hpindicators.com/api',
  DEFAULT_API_URL: 'https://www.hpindicators.com/api/api/',
  DEFAULT_ERROR_MSG: 'Internal server process error. Try again later.',
  CONNECTION_ERROR_1: 'Oops! Something went wrong!',
  CONNECTION_ERROR_2:
    'Problem with internet connection. Please make sure that your device is not switched to airplane mode.',
  SITE_NAME: 'High Performance Indicators',
  SITE_URL: 'https://www.hpindicators.com/',
  PROJECT_FOLDER_RELATIVE_PATH: '',
  REC_PER_PAGE: 10,
  USER_PROFILE_IMAGE_PATH: 'https://www.hpindicators.com/api/files/profile/',
  USER_PROFILE_THUMB_IMAGE_PATH:
    'https://www.hpindicators.com/api/files/profile/thumb/',
  MAX_DATALIST_SELECTION: 5,

  MARKER_COLOR_ARRAY: [MARKER_COLOR_ARRAY, MARKER_COLOR_ARRAY]
};
